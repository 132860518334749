/*************************************************************************************************
	Divi - PageSpeed Booster - Setup with localized options - Init
*************************************************************************************************/

(function($,w){$(function(){

	class dpsbInit
	{

		setProperties()
		{

			this.props  = dpsbPHP.props; // localization

			this.target = $( '<div id="lazyStyle" / >' ) // Styles body
				.appendTo( 'body' )
					.append( '<style>.lazycss{opacity:0}</style>' );

		} // end setProperties

	    constructor()
	    {

	        this.setProperties();

	        this.helperFunctions();

	        this.prepareElements();

			setTimeout( () => this.initialize(), 10 ); // Just to be sure

		} // end constructor

	    helperFunctions()
	    {

			$.fn.deLazy  = function() { return this.removeClass( 'divilazy' ) }

			$.fn.deLoad  = function() { return this.deLazy().removeAttr( 'data-loader' ) }

			$.fn.hasType = function( attr ) { return this.attr( 'type' ).includes( attr ) }

			$.fn.inClass = function( attr ) { return this.attr( 'class' ).includes( attr ) }

		} // end helperFunctions

	    prepareElements( self = this )
	    {

			$( 'source.divilazy' ).each( function( index )
			{

				const $this = $( this );

				if ( $this.hasType( 'video' ) )
				{

					if ( $this.hasClass( 'nobg' ) )
						return self.prepareVideo( $this, 'normalVideo' );

					else
						return self.prepareVideo( $this, 'backgroundVideo' );

				}

				if ( $this.hasType( 'audio' ) )
					return $this.closest( '.mejs-container' ) // tag:audio do not trigger audio callback on scrolling
								.addClass( 'divilazy' )
									.attr( 'data-loader', 'audio' );

			});

		} // end prepareElements

	    initialize( self = this )
	    {

			let lazyInstance = $( self.props.selectors ).Lazy(
			{

			    bind            : self.props.bind,
				delay           : self.props.delay,
			    effect          : self.props.effect,
			    combined	    : self.props.combined,
			    attribute       : self.props.attribute,
			    threshold       : self.props.threshold,
			    chainable       : self.props.chainable,
			    effectTime      : self.props.effectTime,
			    controller      : self.props.controller,
			    autoDestroy     : self.props.autoDestroy,
			    visibleOnly     : self.props.visibleOnly,
			    placeholder     : self.props.placeholder,
			    defaultImage    : self.props.defaultImage,
			    scrollDirection : self.props.scrollDirection,

			    // Background images with parallax
			    backgroundParallax: function( el )
			    {

					return self.backgroundCallback
					(

						el,
						self.setBgImages,
						'span.et_parallax_bg_wrap',
						'span.et_parallax_bg'

					);

			    }, // end backgroundParallax

			    // Background videos
				backgroundVideo: function( el )
				{

					return self.backgroundCallback
					(

						el,
						self.setBgVideos,
						'span.et_pb_section_video_bg',
						'video'

					);

				}, // end backgroundVideo

				// Background videos
				backgroundImage: function( el )
				{

					return self.backgroundImage( el );

				}, // end backgroundImage

				// Slider Thumbnails
				dataImage: function( el )
				{

					if ( el.inClass( 'video_slider' ) )
					{

						return self.videoSlider( el );

					}

				}, // end dataImage

				// Background images CSS:style:tag
				backgroundStyle: function( el )
				{

					return self.backgroundStyle( el );

				}, // end backgroundStyle

				// Videos and VideoSlides
				normalVideo: function( el )
				{

					return self.normalVideo( el );

				}, // end normalVideo

				// Audiofiles
				audio: function( el )
				{

					return self.audio( el );

				}, // end audio

				// Google Maps
				googleMaps: function( el )
				{

					return window.et_pb_map_init
					(

						el.html( el.deLoad().html().replace( /data-lazy-/g, 'data-' ) )

					);

				}, // end googleMaps

				// Post Slider
				postSlider: function( el )
				{

					return el.deLoad().find( 'div.et_pb_slide' ).each( function( index )
					{

						$( this ).css( 'backgroundImage', $( this ).data( 'src' ) ).deLoad().removeAttr( 'data-src' );

					});

				}, // end postSlider

				// Slider
				slider: function( el )
				{

					return el.deLoad().removeClass( 'lazycss' ).children( 'div.et_pb_slides' ).find( 'div.et_pb_slide' ).each( function( index )
					{

						const img = $( this ).find( 'img' );

						img.attr( 'src', img.data( 'lazy' ) )
								.attr( 'srcset', img.data( 'srcset' ) )
									.deLazy().removeAttr( 'data-lazy data-srcset' );

					});

				}, // end postSlider

		        // Filterable Portfolio
		        filtPortfolio: function( el )
		        {

		        	return self.filtPortfolio( el );

		        }, // end filtPortfolio

				// Element was handled
				afterLoad: function( el )
				{

					if ( el.hasClass( 'dpsbiframe' ) && 'builder' === self.props.controller )
						el = self.fitVid( el );

            		return el.deLazy();

		        }, // end afterLoad

				// Clean up everything
				onFinishedAll: function()
				{

		            if ( ! this.config( 'autoDestroy' ) )
		                return this.destroy();

		        }, // end onFinishedAll
			});

			setTimeout( () => $( w ).one( 'scroll', function()
			{

				lazyInstance.config( 'threshold', self.props.thresholdScroll );

			}) , 100 );

	    } // end initialize

		backgroundCallback( el, callback, select, source, self = this )
		{

		    callback( el, self ); // Need to set self from outer scope to the callback

			// also config elements with display:none
			return el.closest( select )
					   .siblings( select )
							.each( function( index )
							{

								callback( $( this ).find( source ), self );

							});

		} // end backgroundCallback

		backgroundImage( el )
		{

			let set = ( el ) =>
			{

				return el.css( 'backgroundImage', el.data( 'src' ) )
					    		  .removeAttr( 'data-loader data-src' )
					    			  .deLazy();

			}

			if ( el.parent().parent().hasClass( 'et_pb_gallery_post_type' ) )
			{

				el.siblings( 'div.et_pb_slide' ).each( function( index )
				{

					let $this = $( this );

					return set( $this );

				});

			}

			return set( el );

		} // end backgroundImage

	    setBgImages( el, self )
	    {

	        return el.css( 'backgroundImage', el.data( 'background' ) )
	        		  .removeAttr( 'data-loader data-background' )
	        			  .deLazy();

		} // end setBgImages

	    setBgVideos( el, self )
	    {

			const [ source, childs ] = [ el.attr( 'src' ), el.children( 'source' ) ];

			childs.each( function( index )
			{

				const $this = $( this );
				const [ suff, selfSource ] = [ [ '.mp4', '.webm' ], $this.data( 'lazyvideo' ) ];

				suff.forEach( ( val ) =>
				{

					if ( undefined !== source )
						if ( source.includes( val ) && selfSource.includes( val ) )
							el.attr( 'src', selfSource );

				});

				self.sourceAttr( $this, selfSource );

			});

			return el.deLoad();

		} // end setBgVideos

		backgroundStyle( el )
		{

			this.target.append( '<style>' + el.data( 'lazystyle' ) + '</style>' );

			return el.fadeTo( this.props.effectTimeBg, 1 )
					  .removeAttr( 'data-loader data-lazystyle' )
						  .deLazy().removeClass( 'lazycss' );

		} // end backgroundStyle

	    normalVideo( el, self = this )
	    {

	    	if ( el.hasClass( 'wp-video' ) )
	    	{

	    		return self.setVideos( el.deLoad().find( 'div.mejs-mediaelement' ).find( 'video' ) );

	    	}

			// VideoSlider:ensure to set the source to hidden elements
			// because they're not triggered on scroll
			const slider = el.closest( '.et_pb_video_wrap' )
								.parent( '.et_pb_slide' );

			if ( slider.length )
			{

				slider.siblings( '.et_pb_slide' ).each( function( index )
				{

					const el = $( this ).find( 'video' );

					self.setVideos( el );

					el.deLoad();

				});
			}

			// Video
			return self.setVideos( el );

		} // end normalVideo

	    setVideos( el, self = this )
	    {

	    	// Ensure that source is set to video, because webkit browsers do not support changing source and video.load()
			const [ childs, mp4 ] =
					[

						el.children( 'source' ),
						!! document.createElement( 'video' ).canPlayType( 'video/mp4; codecs=avc1.42E01E,mp4a.40.2' ),

					]

			childs.each( function( index )
			{

				const selfSource = $( this ).data( 'lazyvideo' );

				self.sourceAttr( $( this ), selfSource );

				if ( ! mp4 && selfSource.includes( '.webm' ) )
					return self.sourceAttr( el.deLoad(), selfSource ); // webm to video:src

				if ( mp4 && selfSource.includes( '.mp4' ) )
    				return self.sourceAttr( el.deLoad(), selfSource ); // mp4 to video:src

			});

		} // end setVideos

	    videoSlider( el )
	    {

			let sibles,
				slides = {},
				number = ( el ) => parseInt( el.attr( 'class' ).replace ( /[^\d.]/g, '' ) ),
				clear  = ( el ) => el.deLazy().removeAttr( 'data-loader data-dataimage' );

			el.parent( 'div.et_pb_slides' ).children( 'div.et_pb_slide' ).each( function( index )
			{

				const $this = $( this ); let overlay;

				slides[number( $this )] = $this.data( 'dataimage' );

				overlay = clear( $this.attr( 'data-image', $this.data( 'dataimage' ) ) ).find( 'div.et_pb_video_overlay' );

				overlay.css( 'backgroundImage', overlay.data( 'src' ) )
						.deLazy().removeAttr( 'data-loader data-src' );

			});

			return el.closest( 'div.et_pb_video_slider' )
						.children( 'div.et_pb_carousel' )
							.find( 'div.et-carousel-group' )
								.children( 'div.et_pb_carousel_item' )
									.each( function( index )
									{

										const $this = $( this );

										$this.children( 'div.et_pb_video_overlay' )
											.css( 'backgroundImage', 'url(' + slides[ $this.data( 'slide-id' ) ] + ')' );

									});

		} // end setVideos

		filtPortfolio( el, self = this )
		{

        	// el.children( 'div.et_pb_portfolio_items_wrapper' )
        	el.find( 'div.et_pb_portfolio_item' ).each( function( index )
			{

				const img = $( this ).find( 'img' );

				img.css( 'display', 'none' ).attr( 'src', img.data( 'lazy' ) )
						.attr( 'srcset', img.data( 'srcset' ) )
							.removeAttr( 'data-lazy data-srcset' )
								.deLazy();

				img[self.props.effect]( self.props.effectTime );

			});

        	return window.et_pb_filterable_portfolio_init( el.deLoad() );

        } // end filtPortfolio

	    sourceAttr( el, source, type = 'video' )
	    {

			return el.attr( 'src', source )
						.deLazy()
							.removeAttr( 'data-lazy' + type );

		} // end sourceAttr

	    prepareVideo( self, loader )
	    {

			setTimeout( ( self, loader ) =>
			{

				let el;

		    	if ( self.parent( 'video' ).hasClass( 'wp-video-shortcode' ) )
		    		el = self.closest( 'div.wp-video' );

		    	else
		    		el = self.parent( 'video' );

				return el.addClass( 'divilazy' )
							.attr( 'data-loader', loader );

			}, 10, self, loader ); // ensure the element is available

		} // end prepareVideo

	    audio( el )
	    {

	    	const audio  = el.find( 'audio' );
	    	const source = audio.children( 'source' );
	    	const track  = source.data( 'lazyaudio' );

	    	this.sourceAttr( source, track, 'audio' );

			audio.attr( 'src', track );

			return el.deLoad();

		} // end audio

		fitVid( el ) // based on jquery.fitvids.js
		{

			if ( ( ! el.css( 'height' ) && ! el.css( 'width' ) ) && ( isNaN( el.attr( 'height' ) ) || isNaN( el.attr( 'width' ) ) ) )
			{

				el.attr( 'height', 9 );
				el.attr( 'width', 16 );

			}

			let height = ( el.attr( 'height' ) && ! isNaN( parseInt( el.attr( 'height' ), 10 ) ) ) ? parseInt( el.attr( 'height' ), 10 ) : el.height(),
			    width  = ! isNaN( parseInt( el.attr( 'width' ), 10 ) ) ? parseInt( el.attr( 'width' ), 10 ) : el.width(),
			    ratio  = height / width;

			el.wrap( '<div class="fluid-width-video-wrapper"></div>' )
				.parent( '.fluid-width-video-wrapper' )
					.css( 'padding-top', ( ratio * 100 ) + '%' );

			return el.removeAttr( 'height' ).removeAttr( 'width' ).removeClass( 'dpsbiframe' );

		} // end fitVid
	} // end class dpsbInit

	new dpsbInit;

});}(jQuery,window));
